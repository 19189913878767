import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  StyledAddress,
  StyledConfirmation,
  StyledStreet,
  StyledTownZip,
  StyledName,
  StyledHeading,
  StyledContainer,
  StyledRow,
  StyledTimeFrame,
  StyledTimeFrameTo,
  StyledTimeFrameFrom, StyledPageHeading, StyledSubHeading
} from "./ConfirmationResponse.styles";
import {useNavigate} from "react-router-dom";
import {Col} from "react-grid-system";
import {useAppContext} from "../../contexts/AppContext";
import {ButtonPrimary} from "pyrexx-react-library";

const ConfirmationResponse = (props) => {
  const {savedValues = []} = props
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {currentLanguage} = useAppContext()

  const redirectToHome = () => {
    navigate('/')
  }

  return (
    <StyledContainer>
      <StyledRow justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6} align='center'>
          <StyledPageHeading>{t('THANK YOU FOR THE INFORMATION')}</StyledPageHeading>
          <StyledSubHeading>{t('WE ARE CONFIRMING THE APPOINTMENT FOR THE FOLLOWING DATA')}</StyledSubHeading>
        </Col>
      </StyledRow>
      <StyledRow justify='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          <StyledConfirmation>
            <StyledAddress>
              <StyledHeading>{t("NAME")}</StyledHeading>
              <StyledName>{savedValues.firstName} {savedValues.lastName} </StyledName>
            </StyledAddress>

            <StyledAddress>
              <StyledHeading>{t("ADDRESS")}</StyledHeading>
              <StyledStreet>{savedValues.street}</StyledStreet>
              <StyledTownZip>{savedValues.zip}, {savedValues.town} </StyledTownZip>
            </StyledAddress>

            {
              (savedValues.timeframeFrom !== null && savedValues.timeframeTo !== null)
                ? (
                  <StyledTimeFrame>
                    <StyledHeading>{t('TIMESLOT')}</StyledHeading>
                    <StyledTimeFrameFrom>
                      {(new Date(savedValues.timeframeFrom)).toLocaleString(currentLanguage, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </StyledTimeFrameFrom> - <StyledTimeFrameTo>
                       {(new Date(savedValues.timeframeTo)).toLocaleString(currentLanguage, {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </StyledTimeFrameTo>
                  </StyledTimeFrame>
                )
                : ''
            }

          </StyledConfirmation>
        </Col>
      </StyledRow>
      <StyledRow justify='center'>
        <Col xs={12} sm={10} md={8} lg={6} align='center'>
          <ButtonPrimary onClick={redirectToHome}>
            {t('BACK HOME')}
          </ButtonPrimary>
        </Col>
      </StyledRow>
    </StyledContainer>
  )
}
export default ConfirmationResponse
