import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ButtonSecondary } from 'pyrexx-react-library'

const Navigator = (props) => {

  const { prevStep = '', nextStep = '', isNextButtonDisabled = false, maxSteps = '', currentStep = '' } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const isFinalStep = maxSteps !== '' && maxSteps === currentStep;
  const buttonText = isFinalStep ? t('SEND INQUIRY') : t('NEXT');

  return (
    <Row align='center' justify='center'>
      {prevStep.length > 0 ? (
        <Col xs={6} align='center'>
          <ButtonSecondary
            className='backButton'
            onClick={() => navigate(prevStep)}
            type='button'
          >
            {t('BACK')}
          </ButtonSecondary>
        </Col>
      ) : null}

      <Col xs={6} align='center'>
        {nextStep.length > 0 ? (
          <ButtonSecondary type='button' onClick={() => navigate(nextStep)}>
            {t('NEXT')}
          </ButtonSecondary>
        ) : (
          <ButtonSecondary type='submit' disabled={isNextButtonDisabled}>
            {buttonText}
          </ButtonSecondary>
        )}
      </Col>
    </Row>
  )
}

Navigator.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  isNextButtonDisabled: PropTypes.bool,
}

export default Navigator
