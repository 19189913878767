import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { portalTheme } from 'pyrexx-react-library'

export const StyledContainer = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};
`

export const ImgStyled = styled.img`
  width: 100%;
  margin-top:1rem;
`

export const TextStyled = styled.h2`
  text-align: center;
  padding: 0 2rem;
`
